.form-container
{
    padding-bottom: 18px;
}

.form-singleline-half-right
{
    height: 54px;

    color: #788490;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    -webkit-filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));
    filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));

    border-style: none;
}

.form-singleline
{
    height: 54px;

    color: #788490;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    -webkit-filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));
    filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));

    border-style: none;
}

.form-singleline-dropdown
{
    margin-top: 18px;
    padding-left: 12px;
    padding-right: 12px;
    
    width: 100%;
    height: 54px;

    color: #788490;

    background-color: #ffffff;
    background-image: url('../image/icon-arrow-down.svg');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: right 12px top 50%;
    -webkit-appearance: none;

    border-style: none;
    border-radius: 0;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    -webkit-filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));
    filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));    
}

@media (min-width: 576px)
{
    .form-singleline-dropdown
    {
        margin-top: 0px;
    }
}

.form-singleline-value
{
    margin-top: 18px;
    padding-left: 12px;
    padding-right: 12px;
    
    width: 100%;
    height: 54px;

    color: #788490;

    background-color: #ffffff;

    border-style: none;
    border-radius: 0;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    -webkit-filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));
    filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));    
}

@media (min-width: 576px)
{
    .form-singleline-value
    {
        margin-top: 0px;
    }
}

.form-multiline
{
    margin-top: 18px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    
    width: 100%;
    height: 108px;

    color: #788490;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    -webkit-filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));
    filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));

    border-style: none;
}

.form-accept
{
    display: none;
}

select::-ms-expand
{
    display: none;
}

.form-checkbox-container
{
    height: 32px;
    margin-top: 18px;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #788490;
}

.form-checkbox input
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-checkbox
{
    position: relative;
    display: block;
    margin-top: 10px;
    padding-top: 6px;
    line-height: 20px;
}

.form-checkbox span
{
    display: block;
    margin-left: 42px;

    line-height: 20px;
    text-align: left;
}
  
.form-checkbox span::before
{
    content: "";
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    left: 0;
    top: 0;
    background: #ffffff;

    -webkit-filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));
    filter: drop-shadow(5px 5px 25px rgb(200, 200, 200)); 
}

.form-checkbox:hover
{
    cursor: pointer;
}

.form-checkbox-active input
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-checkbox-active
{
    position: relative;
    display: block;
    margin-top: 10px;
    padding-top: 6px;
    line-height: 20px;
}

.form-checkbox-active span
{
    display: block;
    margin-left: 42px;

    line-height: 20px;
    text-align: left;
}
  
.form-checkbox-active span::before
{
    content: "";
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    left: 0;
    top: 0;
    background: #d92a1c;

    border-style: solid;
    border-color: #ffffff;
    border-width: 5px;

    -webkit-filter: drop-shadow(5px 5px 25px rgb(200, 200, 200));
    filter: drop-shadow(5px 5px 25px rgb(200, 200, 200)); 
}

.form-checkbox-active:hover
{
    cursor: pointer;
}

.form-text
{   color: #788490;
    text-decoration: none;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.form-hyperlink
{
    color: #788490;
    text-decoration: underline;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.form-hyperlink:hover
{
    color: #000000;
    cursor: pointer;
}

.form-button
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    
    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    width: 100%;
    height: 54px;
    margin-top: 36px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;

    font-size: 14px;

    float: left;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.form-button:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.form-button:hover
{
    color: #fff;

    cursor: pointer;
}
  
.form-button:hover:after
{
    width: 100%;
}

.form-button span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

/* Make thin padding on the responsive columns */
@media (min-width: 576px)
{
    .form-col-left
    {
        padding-right: 3px;
    }

    .form-col-center
    {
        padding-left: 9px;
        padding-right: 9px;
    }

    .form-col-right
    {
        padding-left: 3px;
    }
}
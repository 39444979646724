.footer-align
{
    justify-content: center;
}

.footer-container
{
    background-color: #ffffff;
}

.footer-title
{
    padding-top: 25px;
    padding-bottom: 25px;

    font-family: ClanPro-Medium;
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;

    color: #101820;
}

.footer-configuration
{
    padding-bottom: 25px;
}

.footer-column
{
    float: left;
    width: 50%;
}

.footer-function-column
{
    float: left;
    width: 50%;
}

.footer-text
{
    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 24px;

    color: #878b8f;
}

.footer-text-dark
{
    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 24px;

    color: #101820;
}

.footer-stone-image
{
    margin-right: 12px;
    margin-bottom: 12px;
    height: 96px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.footer-button-download
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 16px;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    height: 48px;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 19px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.footer-button-download:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.footer-button-download:hover
{
    color: #fff;

    cursor: pointer;
}
  
.footer-button-download:hover:after
{
    width: 100%;
}

.footer-button-download span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.footer-button-download span::before
{
    content: "\e919";

    width: 28px;
    height: 24px;

    padding-right: 10px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}

.footer-button-order
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 16px;

    color: #FFFFFF;

    border: none;

    background-color: #788490;

    display: block;
    position: relative;
    height: 48px;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 19px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.footer-button-order:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.footer-button-order:hover
{
    color: #fff;

    cursor: pointer;
}
  
.footer-button-order:hover:after
{
    width: 100%;
}

.footer-button-order span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.footer-button-order span::after
{
    content: "\e91b";

    float: right;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}

.footer-help
{
    padding: 20px;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 24px;

    background-color: #e4eaed;

    color: #101820;
}

.footer-help-link
{
    text-decoration: underline;
    color: #101820;
}

.footer-help-link:hover
{
    cursor: pointer;
}

.footer-help-link-color
{
    text-decoration: underline;
    color: #d92a1c;
}

.footer-help-link-color:hover
{
    cursor: pointer;
}

.footer-calculator
{
    padding: 20px;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 24px;

    background-color: #edf1f9;

    color: #101820;
}

.footer-configurator-dropdown
{
    display: inline-block;
    position: relative;

    width: 100%;

    box-shadow: 5px 5px 25px rgb(200, 200, 200, 0.4);
}

.footer-configurator-dropdown:hover .footer-configurator-dropdown-content
{
    display: block;
}

.footer-configurator-dropdown:hover .footer-configurator-dropdown-button
{
    color: #878b8f;
}

.footer-configurator-dropdown:hover .footer-configurator-dropdown-description
{
    color: #878b8f;
}

.footer-configurator-dropdown-button
{
    padding-left: 16px;
    padding-right: 16px;
    height: 54px;
    z-index: auto;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #000000;

    background-color: #ffffff;

    border: none;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;
}

.footer-configurator-dropdown-button::after
{
    content: "\e915";

    display: inline-block;
    position: absolute;

    top: 15px;
    right: 16px;

    font-family: Icons;
    font-size: 14px;
}

.footer-configurator-dropdown-button:hover
{
    position:relative;
    z-index: 1;
}

.footer-configurator-dropdown-button-content
{
    display: inline-block;

   padding-top: 23px;
}

.footer-configurator-dropdown-description
{
    position: absolute;
    top: 16px;
    left: 16px;

    padding: 0;
    margin: 0;

    font-family: ClanPro-News;
    font-size: 10px;
    font-weight: 500;
    line-height: 6px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #000000;
}

.footer-configurator-dropdown-content
{
    z-index: 3;

    overflow-y: scroll;

    display: none;
    position: absolute;

    width: 304px;
    /*  max-height: 100%; */

    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    background-color: #ffffff;
    color: #878b8f;

    float: left;

    font-family: ClanPro-News;
    font-size: 11px;
    -webkit-font-smoothing: antialiased;

    box-shadow: 0px 24px 24px 0px rgb(200, 200, 200, 0.4);
}

.footer-configurator-dropdown-content-items
{
    padding-top: 8px;
    padding-bottom: 0px;
    
    color: #878b8f;
}

.footer-configurator-dropdown-item
{
    position: relative;

    width: 264px;
    height: 64px;

    border: none;
    padding: 0;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;
}

.footer-configurator-dropdown-item:hover
{
    cursor: pointer;
}

.footer-configurator-dropdown-item:hover .footer-configurator-dropdown-item-border
{
    opacity: 1;
}

.footer-configurator-dropdown-item-image
{
    width: 100%;
    height: 100%;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.footer-configurator-dropdown-item-border
{
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    border: 4px solid #da291c;
    
    transition: all .25s ease-out;
}

.footer-configurator-dropdown-item-border-selected
{
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    border: 4px solid #da291c;
}

.footer-configurator-dropdown-item-label
{
    position: absolute;
    right: 0;
    bottom: -4.15px;

    padding-left: 11px;
    padding-right: 11px;
    padding-top: 7px;

    background-color: #ffffff;

    font-family: ClanPro-Medium;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    display: table-cell;
    vertical-align: bottom;

    color: #000000;

    white-space: nowrap;
}

.footer-configurator-dropdown-item-checkmark
{
    height: 9px;
    padding-right: 4px;
}